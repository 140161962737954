
  // IMPORTANT: This file was generated by tools/build.js
  // If you edit it, your changes will be overwritten once dev server restarts
  export default {
  "CONFIG_ID": "shopware:prod",
  "CUSTOM_APP_NAME": "Shopware PaaS",
  "CUSTOM_ASSETS_URL": "https://main-bvxea6i-mxhyx2r4fchvc.fr-4.platformsh.site",
  "CUSTOM_BLACKFIRE_CARD_LANGUAGE": [
    "php",
    "python"
  ],
  "CUSTOM_BRANDING_MESSAGE": false,
  "CUSTOM_CLI_COMMAND": "shopware",
  "CUSTOM_CONFIG_DIR_NAME": false,
  "CUSTOM_CONSOLE_ID": "shopware",
  "CUSTOM_CONSOLE_TITLE": false,
  "CUSTOM_CSP": false,
  "CUSTOM_DEFAULT_BRANCH": false,
  "CUSTOM_FEDERATED_AUTH_PROVIDERS": false,
  "CUSTOM_FOOTER_TEXT": false,
  "CUSTOM_LOGO_HEIGHT": 34,
  "CUSTOM_LOGO_WIDTH": 160,
  "CUSTOM_PROFILING_LANGUAGE": [
    "php",
    "python",
    "golang",
    "java",
    "node",
    "ruby"
  ],
  "CUSTOM_THEME": false,
  "CUSTOM_WINDOW_TITLE": "Shopware PaaS",
  "CUSTOM_URL_BILLING": false,
  "CUSTOM_URL_CLI_DOWNLOAD": "https://cli.shopware.com/installer",
  "CUSTOM_URL_DOCS": "https://developer.shopware.com/docs/products/paas/",
  "CUSTOM_URL_DOCS_STATUS_CHECK": false,
  "CUSTOM_URL_PROJECT_CREATION": "https://developer.shopware.com/docs/products/paas/",
  "CUSTOM_URL_SUBSCRIPTION_DELETE_REDIRECT": false,
  "CUSTOM_URL_SUBSCRIPTION_UPGRADE": false,
  "CUSTOM_URL_SUPPORT": false,
  "CUSTOM_URL_VENDOR_CONSOLE": false,
  "URL_ACCOUNTS": "https://accounts.platform.sh",
  "URL_ANNOUNCEMENTS": "https://platform.sh/product/announcements/shopware.json",
  "URL_API_PROXY": "https://proxy.shopware.com",
  "URL_AUTH": "https://psh-auth.shopware.com",
  "URL_GITHUB": "https://api.github.com",
  "URL_GITLAB": "https://gitlab.com",
  "URL_LOGOUT": "https://api.shopware.com/oauth/logout",
  "URL_REDIRECT_AFTER_LOGIN": false,
  "URL_REDIRECT_AUTH_USER": "https://console.shopware.com",
  "URL_SCREENSHOT_API": "https://screenshot.shopware.com/screenshot",
  "URL_ZENDESK": "https://support.platform.sh/hc/en-us/requests",
  "ID_API_CLIENT": "ui@shopware",
  "ID_COOKIELAW": false,
  "ID_DOCS_TOKEN": "509f11463964c0434b8281def0d4d166e55d32663c63f6f0f761b0b104c6620c",
  "ID_DRIFT_CHATBOT": false,
  "ID_HEAP": false,
  "ID_PLATFORM_TREE": false,
  "ID_SENTRY_DNS": "https://7682ebc48eef70826bdca7ac9f08b505@o38989.ingest.us.sentry.io/4508160398852096",
  "ID_SENTRY_ORGANIZATION": "platformsh",
  "ID_SENTRY_PROJECT": "console",
  "ID_STATUS_PAGE": false,
  "ID_ZENDESK": false,
  "DISABLE_ALL_PROJECTS": true,
  "ENABLE_ACCOUNT_PAGES": true,
  "ENABLE_ACCOUNT_SECURITY_ROUTE": false,
  "ENABLE_ADD_DOMAIN_SUPPORT": false,
  "ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT": true,
  "ENABLE_ANNOUNCEMENTS": true,
  "ENABLE_AUTOMATED_BACKUPS": false,
  "ENABLE_BILLING": true,
  "ENABLE_BLACKFIRE_CARD": true,
  "ENABLE_CARBON_EMISSIONS_DASHBOARD": false,
  "ENABLE_CHANGE_USERNAME_EMAIL": true,
  "ENABLE_COMMAND_MENU": true,
  "ENABLE_COMMAND_MENU_DOCS": false,
  "ENABLE_CONNECTED_ACCOUNTS_ROUTE": false,
  "ENABLE_CONTINUOUS_PROFILING_SELLABLE": false,
  "ENABLE_CREATE_ORGANIZATION": false,
  "ENABLE_CREATE_PROJECT": true,
  "ENABLE_CUSTOM_BANNER_MESSAGE": false,
  "ENABLE_CUSTOM_LEFT_PANEL": false,
  "ENABLE_DEPLOYMENT_OVERLAY": true,
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT": false,
  "ENABLE_EDIT_PLAN": true,
  "ENABLE_ENVIRONMENT_DOMAINS": true,
  "ENABLE_ENVIRONMENT_EMAIL_SETTING": true,
  "ENABLE_ENVIRONMENT_SOCKET": false,
  "ENABLE_EXTERNAL_URL": false,
  "ENABLE_FIRST_PROJECT_INCENTIVE": false,
  "ENABLE_FREE_TRIAL_DIALOG": false,
  "ENABLE_GREEN_DISCOUNT": false,
  "ENABLE_HIPAA_COMPLIANCE": false,
  "ENABLE_HTTP_METRICS": false,
  "ENABLE_LOCAL_DEPLOYMENT_TARGET": false,
  "ENABLE_MARKETING_CHECKBOX": true,
  "ENABLE_MIGRATION_FLOW": false,
  "ENABLE_MULTIVERSION_DEPLOYMENT": false,
  "ENABLE_NOTIFICATION_SETTINGS": true,
  "ENABLE_OBSERVABILITY_SANDBOX": false,
  "ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS": true,
  "ENABLE_ORGANIZATION": true,
  "ENABLE_ORGANIZATION_FIRST_ONBOARDING": false,
  "ENABLE_ORGANIZATION_PLANS_PERMISSION": true,
  "ENABLE_PROFILING": false,
  "ENABLE_PROJECT_ACCESS_SELECT": false,
  "ENABLE_PROJECT_PROVISIONING_WIZARD": false,
  "ENABLE_PROJECT_SETUP_TIPS": false,
  "ENABLE_PROJECT_SETUP_WIZARD": true,
  "ENABLE_REACTIVATE_TRIAL": false,
  "ENABLE_REDUCED_WIZARD": true,
  "ENABLE_REGISTRATION": false,
  "ENABLE_RESET_PROFILE_PASSWORD": true,
  "ENABLE_RESOURCE_METRICS_THRESHOLD": true,
  "ENABLE_SENTRY": true,
  "ENABLE_SERVICE_TREE": true,
  "ENABLE_SINGLE_STEP_SINGUP_FLOW": false,
  "ENABLE_SOURCE_OPERATION": true,
  "ENABLE_SOURCE_REPOSITORY_TOOLTIP": false,
  "ENABLE_SSO": true,
  "ENABLE_STAFF_TEST_MODE": false,
  "ENABLE_STRIPE": false,
  "ENABLE_SUBSCRIBE_FLOW": false,
  "ENABLE_SUPPORT_SELLABLE": false,
  "ENABLE_TERMS_AND_ACCEPTANCE_CHECKBOX": true,
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS": false,
  "ENABLE_UNBRANDED_ILLUSTRATIONS": true,
  "ENABLE_UPDATE_PROJECT_THROUGH_SUBSCRIPTION": false,
  "ENABLE_USAGE_BASED_BILLING_PAGES": false,
  "ENABLE_USER_MANAGEMENT": true,
  "ENABLE_VOUCHERS": false,
  "ENABLE_ZENDESK": true,
  "VERSION_NUMBER": "3.2.76"
}