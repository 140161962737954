const en = {
  "footer.message": "Version {appVersion} - Shopware PaaS",
  "url.logo_url": "https://www.shopware.com",
  error_status: "You can check Shopware PaaS status <a>here</a>",
  deploy_key_description:
    "Add this SSH public key to your private code repositories to allow Shopware PaaS to access them during the build process.",
  "newsletter.description":
    "Yes, I wish to receive special offers and information from Shopware PaaS",
  yaml_routes_description:
    "# Each route describes how an incoming URL is going to be processed by Shopware PaaS.",
  "setup.type.empty.description":
    "Bring your own code or migrate an existing project to Shopware PaaS",
  api_token_help_text:
    "Use API Tokens to access Shopware PaaS from other CI services or automation tools.",
  "tickets.list.quicklinks.paragraph":
    "Can’t find what you’re looking for? Try our quick links below to learn more or connect with your Shopware PaaS peers in the community forum.",
  "integration.configure.webhook.intro":
    "Webhooks allow you to set up integrations, which subscribe to certain activities on in Shopware PaaS projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments.",
  "integration.configure.health_webhook.intro":
    "Webhooks allow you to set up integrations, which subscribe to certain activities on in Shopware PaaS projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments.",
  "integration.configure.script.intro":
    "Activity Scripts respond to activities in Shopware PaaS projects, such as creating or merging branches, synchronizing data with parent environments, or activating new environments. You can write code that is triggered by these activities and takes action in response. {br} Activity Scripts are powered by a lightweight JavaScript engine, and has runtime access to objects representing your project and environment. Find out more in our <a>documentation</a>.",
  "organizations.settings.add_users_text":
    "Invite new or existing Shopware PaaS users to your organization.",
  CLI_command_name: "shopware",
  "links.CLI_download": "https://cli.shopware.com/installer",
  "links.CLI_repository": "https://cli.shopware.com/",
  "setup.cli.install.label": "Install CLI",
  "setup.cli.install.code":
    "curl -sfS https://cli.shopware.com/installer | php",
  add_user_agreement:
    "I understand that adding user licenses might result in additional charges, depending on the terms outlined in my contract.",
  "links.documentation.wizard":
    "https://developer.shopware.com/docs/products/paas/",
};

export default en;
