const links = {
  "links.documentation":
    "https://developer.shopware.com/docs/products/enterprise-cloud",
  "links.documentation.CLI":
    "https://developer.shopware.com/docs/products/paas/cli-setup",
  "links.documentation.download_code":
    "https://developer.shopware.com/docs/products/paas/cli-setup.html",
  "links.blackfire.connect": "https://blackfire.io/session/connect/shopware"
};

export default links;
